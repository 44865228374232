import React, {
  createContext,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { mergeRefs } from '@app/utils/mergeRefs'
import { sleep } from '@app/utils/sleep'

import classes from './SliderGallery.module.scss'

export type SliderGalleryOnScrollStartedCallback = (data: { type: 'index'; index: number } | { type: 'offset'; offset: number }) => void
export type SliderGalleryOnScrolledCallback = (data: { type: 'index'; index: number } | { type: 'offset'; offset: number }) => void
export type SliderGalleryOnBoundsChangeCallback = (data: { hasPrev: boolean; hasNext: boolean }) => void

export type SliderGalleryProps = PropsWithChildren<{
  className?: string
  containerClassName?: string
  getOffsets: (cntWidth: number, scrollWidth: number) => number[]
  onScrollStarted?: SliderGalleryOnScrollStartedCallback
  onScrolled?: SliderGalleryOnScrolledCallback
  onBoundsChange?: SliderGalleryOnBoundsChangeCallback
}>

export type SliderGalleryRef = {
  next(animated?: boolean): Promise<void>
  prev(animated?: boolean): Promise<void>
  scrollToOffset(offset: number, animated?: boolean): Promise<void>
  scrollToIndex(index: number, animated?: boolean): Promise<void>
  getState: () => { offset: number; offsets: number[] }
  relayout: () => Promise<void>
}

type SliderGalleryContextDescriptor = { width: number; contentWidth: number; offset: number }
const SliderGaleryContext = createContext<SliderGalleryContextDescriptor>({ width: 0, contentWidth: 0, offset: 0 })

export const SliderGallery = forwardRef<SliderGalleryRef, SliderGalleryProps>(function SliderGallery(
  { className, containerClassName, getOffsets, onScrollStarted, onScrolled, onBoundsChange, children },
  ref
) {
  const [offset, setOffset] = useState(0)
  const rootRef = useRef<HTMLDivElement>(null)
  const [animated, setAnimated] = useState(false)
  const animationAbortController = useRef<AbortController | null>(null)
  const [context, setContext] = useState<SliderGalleryContextDescriptor>({ width: 0, contentWidth: 0, offset })
  const cntRef = useRef<HTMLDivElement>(null)

  const scrollToOffsetInner = useCallback(
    async (offset: number, animated = true) => {
      const root = rootRef.current!
      const offsetVal = parseFloat(cntRef.current!.dataset.offset!)
      const max = getOffsets(root.getBoundingClientRect().width, cntRef.current!.scrollWidth).reduce((a, b) => Math.max(a, b), 0)
      animationAbortController.current?.abort()
      const abcnt = new AbortController()
      animationAbortController.current = abcnt
      const noffset = Math.max(0, Math.min(max, offset))
      onBoundsChange?.({ hasPrev: noffset > 0, hasNext: noffset < max })
      const tr = animated && noffset !== offsetVal ? waitTransition(root) : null
      setAnimated(animated)
      setOffset(noffset)
      await tr
      if (animated && !abcnt.signal.aborted) setAnimated(false)
    },
    [getOffsets, onBoundsChange]
  )

  const scrollToOffset = useCallback(
    async (offset: number, animated = true) => {
      onScrollStarted?.({ type: 'offset', offset })
      await scrollToOffsetInner(offset, animated)
      onScrolled?.({ type: 'offset', offset })
    },
    [onScrollStarted, onScrolled, scrollToOffsetInner]
  )

  const scrollToIndex = useCallback(
    async (index: number, animated = true) => {
      const root = rootRef.current!
      const offsets = getOffsets(root.getBoundingClientRect().width, cntRef.current!.scrollWidth)
      if (index < 0 || index >= offsets.length) return
      onScrollStarted?.({ type: 'index', index })
      await scrollToOffsetInner(offsets[index], animated)
      onScrolled?.({ type: 'index', index })
    },
    [getOffsets, onScrollStarted, onScrolled, scrollToOffsetInner]
  )

  const relayout = useCallback(async () => {
    const root = rootRef.current!
    const offset = parseFloat(cntRef.current!.dataset.offset!)
    const offsets = getOffsets(root.getBoundingClientRect().width, cntRef.current!.scrollWidth)
    const max = offsets.reduce((a, b) => Math.max(a, b), 0)
    if (offset > max) {
      scrollToIndex(offsets.length - 1, false)
    }
    let newIndex = offsets.findIndex(o => o >= offset)
    if (newIndex === -1) {
      newIndex = 0
    }
    scrollToIndex(newIndex, true)
  }, [getOffsets, scrollToIndex])

  useImperativeHandle(
    ref,
    () => ({
      scrollToIndex,
      scrollToOffset,
      prev: async (animated = true) => {
        const nextOffset = getOffsets(rootRef.current!.getBoundingClientRect().width, cntRef.current!.scrollWidth).findLast(o => o < offset)
        if (typeof nextOffset !== 'number') return
        return scrollToOffset(nextOffset, animated)
      },
      next: async (animated = true) => {
        const nextOffset = getOffsets(rootRef.current!.getBoundingClientRect().width, cntRef.current!.scrollWidth).find(o => o > offset)
        if (typeof nextOffset !== 'number') return
        return scrollToOffset(nextOffset, animated)
      },
      getState: () => ({ offset, offsets: getOffsets(rootRef.current!.getBoundingClientRect().width, cntRef.current!.scrollWidth) }),
      relayout,
    }),
    [getOffsets, offset, relayout, scrollToIndex, scrollToOffset]
  )

  useEffect(() => {
    const root = rootRef.current!
    const offsetVal = parseFloat(cntRef.current!.dataset.offset!)
    const max = getOffsets(root.getBoundingClientRect().width, cntRef.current!.scrollWidth).reduce((a, b) => Math.max(a, b), 0)
    animationAbortController.current?.abort()
    const abcnt = new AbortController()
    animationAbortController.current = abcnt
    const noffset = Math.max(0, Math.min(max, offsetVal))
    onBoundsChange?.({ hasPrev: noffset > 0, hasNext: noffset < max })
  }, [getOffsets, onBoundsChange, context.contentWidth, context.width])

  useEffect(() => {
    const root = rootRef.current!
    let axis: 'x' | 'y' | null = null
    let startX = 0
    let startY = 0
    let offset = 0

    const max = getOffsets(root.getBoundingClientRect().width, cntRef.current!.scrollWidth).reduce((a, b) => Math.max(a, b), 0)
    let cleanups: (() => void)[] = []
    const mountCleanups: (() => void)[] = []

    const getOffset = (offset: number) => {
      if (offset < 0) {
        offset = -Math.sqrt(Math.abs(offset) * 10)
      }
      if (offset > max) {
        offset = max + Math.sqrt((offset - max) * 10)
      }
      return offset
    }

    const cleanup = async () => {
      await sleep(1)
      cleanups.toReversed().forEach(c => c())
      cleanups = []
    }
    const windowmove = (e: TouchEvent) => {
      if (axis === 'x') e.preventDefault()
    }
    const prevent = (e: { preventDefault: () => void }) => {
      e.preventDefault()
    }
    const pointerdown = (e: PointerEvent) => {
      if (e.button !== 0) return
      if (e.target !== root && !root.contains(e.target as Node)) return

      offset = parseFloat(cntRef.current!.dataset.offset!)
      startX = e.x
      startY = e.y

      document.addEventListener('pointermove', pointermove, { passive: true, capture: true })
      document.addEventListener('pointerup', cleanup, { passive: true, capture: true })
      document.addEventListener('pointercancel', cleanup, { passive: true, capture: true })
      cleanups.push(() => {
        document.removeEventListener('pointermove', pointermove, { capture: true })
        document.removeEventListener('pointerup', cleanup, { capture: true })
        document.removeEventListener('pointercancel', cleanup, { capture: true })
      })
    }
    const pointermove = (e: PointerEvent) => {
      if (!axis) {
        document.addEventListener('dragstart', prevent)
        cntRef.current!.style.userSelect = 'none'
        cntRef.current!.style.removeProperty('transition')
        cleanups.push(() => {
          document.removeEventListener('dragstart', prevent)
          const cnt = cntRef.current
          if (!cnt) return
          cnt.style.removeProperty('user-select')
          cnt.style.transition = originalTransition
        })

        const dx = Math.abs(e.x - startX)
        const dy = Math.abs(e.y - startY)
        const distance = Math.sqrt(dx * dx + dy * dy)
        if (distance < 10) return
        axis = dx > dy ? 'x' : 'y'
        cleanups.push(() => {
          axis = null
        })
        if (axis === 'y') return

        const originalTransition = cntRef.current!.style.transition

        document.addEventListener('click', prevent)
        document.addEventListener('pointerup', pointerup, { passive: true, capture: true })
        document.addEventListener('pointercancel', pointercancel, { passive: true, capture: true })

        cleanups.push(() => {
          document.removeEventListener('pointerup', pointerup, { capture: true })
          document.removeEventListener('pointercancel', pointercancel, { capture: true })
          document.removeEventListener('click', prevent)
        })
      }
      if (axis !== 'x') return
      const delta = e.x - startX
      const newOffset = getOffset(offset - delta)
      cntRef.current!.dataset.offset = `${newOffset}`
      cntRef.current!.style.transform = `translateX(${-newOffset}px)`
    }
    const pointerup = (e: PointerEvent) => {
      if (axis === 'y') return
      const delta = e.x - startX
      const newOffset = getOffset(offset - delta)
      const offsets = getOffsets(root.getBoundingClientRect().width, cntRef.current!.scrollWidth)
      let newIndex = delta < 0 ? offsets.findIndex(o => o >= newOffset) : offsets.findLastIndex(o => o <= newOffset)
      if (newIndex === -1) {
        newIndex = delta < 0 ? offsets.length - 1 : 0
      }
      setOffset(newOffset)
      sleep(1).then(() => {
        scrollToIndex(newIndex)
      })
    }
    const pointercancel = pointerup

    window.addEventListener('touchmove', windowmove, { passive: false, capture: true })
    mountCleanups.push(() => {
      window.removeEventListener('touchmove', windowmove, { capture: true })
    })

    document.addEventListener('pointerdown', pointerdown, { passive: true, capture: true })
    mountCleanups.push(() => {
      document.removeEventListener('pointerdown', pointerdown, { capture: true })
    })

    return () => {
      mountCleanups.forEach(c => c())
      cleanup()
    }
  }, [getOffsets, scrollToIndex, scrollToOffset, context.width, context.contentWidth])

  useEffect(() => {
    const root = rootRef.current!
    const offsets = getOffsets(root.getBoundingClientRect().width, cntRef.current!.scrollWidth)
    const max = offsets.reduce((a, b) => Math.max(a, b), 0)
    if (offset > max) {
      scrollToIndex(offsets.length - 1, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.width])

  useEffect(() => {
    const root = rootRef.current!
    const width = root.getBoundingClientRect().width
    setContext(ctx => ({ ...ctx, width }))
    const observer = new ResizeObserver(_cb => {
      const width = root.getBoundingClientRect().width
      setContext(ctx => ({ ...ctx, width }))
    })
    observer.observe(root)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const cnt = cntRef.current!
    const contentWidth = cnt.scrollWidth
    setContext(ctx => ({ ...ctx, contentWidth }))
    const observer = new MutationObserver(_cb => {
      const contentWidth = cnt.scrollWidth
      setContext(ctx => ({ ...ctx, contentWidth }))
    })
    observer.observe(cnt, { subtree: true, childList: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    setContext(ctx => ({ ...ctx, offset }))
  }, [offset])

  return (
    <SliderGaleryContext.Provider value={context}>
      <div className={cn(classes.root, className)} ref={rootRef}>
        <div
          className={containerClassName}
          data-offset={`${offset}`}
          ref={cntRef}
          style={{ transition: animated ? 'transform 333ms ease-in-out' : undefined, transform: `translateX(${-offset}px)` }}
        >
          {children}
        </div>
      </div>
    </SliderGaleryContext.Provider>
  )
})

const useSliderContext = () => useContext(SliderGaleryContext)

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const AutoHeightContainer = forwardRef<HTMLDivElement, DivProps>(({ ...props }, ref) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const mergedRef = mergeRefs(rootRef, ref)
  const [height, setHeight] = useState<number | undefined>(99999)
  const [mutationId, setMutationId] = useState(0)

  const ctx = useSliderContext()

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setMutationId(id => (id + 1) & 0xff)
    })

    observer.observe(rootRef.current!, { subtree: true, childList: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    const minOffset = ctx.offset
    const maxOffset = ctx.offset + ctx.width
    const ch = Array.from(rootRef.current!.children).filter((e): e is HTMLElement => {
      if (!(e instanceof HTMLElement)) return false
      const l = e.offsetLeft
      const r = l + e.offsetWidth
      return (l >= minOffset && l <= maxOffset) || (r >= minOffset && r <= maxOffset)
    })

    const observer = new ResizeObserver(entries => {
      setHeight(entries.reduce((a, b) => Math.max(a, b.target.scrollHeight), 0))
    })

    for (const child of ch) {
      observer.observe(child)
    }

    const maxHeight = ch.reduce((a, b) => Math.max(a, b.scrollHeight), 0)
    setHeight(maxHeight)

    return () => {
      observer.disconnect()
    }
  }, [ctx.offset, ctx.width, mutationId])

  return <div {...props} ref={mergedRef} style={{ maxHeight: height, transition: 'max-height 333ms ease-in-out', boxSizing: 'content-box', ...props.style }} />
})

export function useGalleryOffsetsGetter({
  cntRef,
  startPadderRef,
  endPadderRef,
}: {
  cntRef: RefObject<HTMLElement>
  endPadderRef?: RefObject<HTMLElement>
  startPadderRef?: RefObject<HTMLElement>
}) {
  return useCallback(
    (width: number, scrollWidth: number) => {
      if (!cntRef.current) return []
      const elements = Array.from(cntRef.current.children)
      const endPadWidth = endPadderRef?.current?.clientWidth ?? 0
      const startPadWidth = startPadderRef?.current?.clientWidth ?? 0
      const maxOffset = scrollWidth + endPadWidth - width
      const offsets: number[] = []
      for (const [index, child] of elements.entries()) {
        if (index === 0) {
          offsets.push(0)
          continue
        }
        const offset = (child as HTMLDivElement).offsetLeft
        if (offset >= maxOffset) {
          offsets.push(maxOffset)
          break
        }
        offsets.push(offset - startPadWidth)
      }
      return offsets
    },
    [cntRef, endPadderRef, startPadderRef]
  )
}

const waitTransition = (element: HTMLElement) => {
  const abortController = new AbortController()
  return Promise.race([
    new Promise<void>(resolve => {
      const handler = () => {
        element.removeEventListener('transitionend', handler)
        element.removeEventListener('transitioncancel', handler)
        abortController.abort()
        resolve()
      }

      element.addEventListener('transitionend', handler)
      element.addEventListener('transitioncancel', handler)
    }),
    sleep(2000, abortController.signal).then(() => {
      throw new Error('Transition timeout')
    }),
  ])
}
